<template>
    <v-layout column fill-height>
        <iframe
            allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            frameborder="0" 
            height="100%" 
            src="https://www.youtube.com/embed/dQw4w9WgXcQ?autoplay=1" 
            title="Rick Astley - Never Gonna Give You Up (Official Music Video)"></iframe>
    </v-layout>
</template>

<script>
export default {
    name: 'ActionExample'
}
</script>